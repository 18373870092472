import React from "react"

import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <h1>NOT FOUND</h1>
    <p>We could not find that link...</p>
  </Layout>
)

export default NotFoundPage
